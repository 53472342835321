import React, { useState } from "react";
import styles from "./index.module.scss";
import { classNames } from "../../common/utils";
import { Button, Input } from "antd";
import axios from "axios";
import { API_URL } from "../../common/constants";

import { TypeAnimation } from "react-type-animation";
function HomePage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitedEmail, setSubmitedEmail] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleSubmit = async (event: any) => {
    setSubmitedEmail(false);
    setError("");
    setLoading(true);
    console.log("formData", formData);

    if (!formData.name || !formData.email) {
      setError("Name and Email are required fields.");
      return;
    }
    try {
      const result = await axios({
        method: "POST",
        baseURL: API_URL,
        data: formData,
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("result", result);

      setFormData({
        name: "",
        email: "",
      });
      setSubmitedEmail(true);
    } catch (error: any) {
      console.error("Form submission failed:", { error });
      setError(error?.message);
    }
    setLoading(false);
  };

  function handleChange(event: any) {
    setError("");
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <div className={classNames("container", styles.main)}>
      <div className={styles.title}>
        <div >
          <div className={styles.subHeader}> Tokenizing</div>
        <TypeAnimation
        className={styles.animationText}
 

        //  sequence={[
        //   `Tokenize\nthe Future`, // actual line-break inside string literal also gets animated in new line, but ensure there are no leading spaces
        //   1000,
        //   '',
        // ]}
        
        sequence={[
          'liquidity', // Types 'One'
          1000, // Waits 1s
          'settlements',
          1000, // Waits 2s
          'the future', // Types 'Three' without deleting 'Two'
          1000, // 
        ]}

          
          speed={40}
          repeat={Infinity}
        />

        </div>
 


        
      </div>

      <div className={styles.subTitle}>
        Heimdall is a business tokenization solution for
        <br />
        real world assets through scalable, compliant frameworks.
      </div>

      <div className={styles.subTitle_mobile}>
        Heimdall is a business tokenization solution for
   
        real world assets through scalable, compliant frameworks.
      </div>
      <div className={styles.divider}></div>
      <div className={styles.touch}>Get in touch</div>
      <div className={styles.subTouch}>
        Looking for a solution? We’d love to help
      </div>
      <div className={styles.inputWrapper}>
        {/* <form action="https://send.pageclip.co/aevib5Ngw7dbgg7mys2NcQh18swGBrKe" className="pageclip-form" method="post" onSubmit={handleSubmitForm}>
        <input type="text" name="name" value={formData.name} onChange={handleChange} />
        <input type="email" name="email" value={formData.email} onChange={handleChange} />

        <button type="submit" className="pageclip-form__submit">
          <span>Send</span>
        </button>
      </form> */}
        <Input
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          // style={{
          //   maxWidth: 200,
          //   paddingTop: 12,
          //   paddingBottom: 12,
          //   height: 48,
          //   background: '#FFFFF',
          //   border: '1px solid #E2E2E2',
          //   boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          //   borderRadius: 5,
          //   marginRight: 10,
          // }}
        ></Input>
        <Input
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          // style={{
          //   maxWidth: 200,
          //   paddingTop: 12,
          //   paddingBottom: 12,
          //   height: 48,
          //   background: '#FFFFF',
          //   border: '1px solid #E2E2E2',
          //   boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          //   borderRadius: 5,
          // }}
        ></Input>
        <Button
          disabled={!formData.name || !formData.email || loading}
          className={styles.submit}
          onClick={handleSubmit}
          loading={loading}
          // type="submit"
        >
          Submit
        </Button>
      </div>
      {submitedEmail && (
        <div className={styles.subTouch}>Thanks, we’ll be in touch!</div>
      )}
      {error && (
        <div className={styles.subTouch} style={{ color: "red" }}>
          {error}
        </div>
      )}
    </div>
  );
}

HomePage.propTypes = {};

export default HomePage;
