const hasOwn = {}.hasOwnProperty;

export const classNames: (...params: any) => string = (...params: any) => {
  const classes = [];

  for (let i = 0; i < params.length; i++) {
    const arg = params[i];
    if (!arg) continue;

    const argType = typeof arg;

    if (argType === "string" || argType === "number") {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      if (arg.length) {
        const inner = classNames(null, arg);
        if (inner) {
          classes.push(inner);
        }
      }
    } else if (argType === "object") {
      if (arg.toString === Object.prototype.toString) {
        for (const key in arg) {
          if (hasOwn.call(arg, key) && arg[key]) {
            classes.push(key);
          }
        }
      } else {
        classes.push(arg.toString());
      }
    }
  }

  return classes.join(" ");
};

export const strimId = (id: string) => `${id.slice(0, 6)}...${id.slice(-6)}`;

export const survey_obj = (survey: string) => {
  var surveyString = survey.replace(/\\/g, "");
  // console.log(JSON.parse(surveyString));
  return JSON.parse(surveyString);
}

export const truncDigits=(inputNumber:number, digits:number)=> {
  const fact = 10 ** digits;
  return Math.floor(inputNumber * fact) / fact;
}