import React from "react";
import { Routes, Route } from "react-router-dom";

// import DefaultLayout from './layouts/DefaultLayout';

// import ClaimPage from "./containers/ClaimPage";
// import TransferAgreementPage from "./containers/TransferAgreementPage";
// import PublicDataPage from "./containers/PublicDataPage";

import NotFoundPage from "./containers/NotFoundPage";
import HomePage from "./containers/HomePage";
// import LoginPage from "./containers/LoginPage";
import HomeLayout from "./layouts/HomeLayout";
// import DefaultLayout from "./layouts/DefaultLayout";
// import DashboardLayout from "./layouts/DashboardLayout";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index element={<HomePage />} />
      </Route>
      {/* <Route path="/public-data" element={<DefaultLayout />}>
        <Route index element={<PublicDataPage />} />
      </Route>
      <Route path="/login" element={<DefaultLayout />}>
        <Route index element={<LoginPage />} />
      </Route>
      <Route path="/claim" element={<DashboardLayout />}>
        <Route index element={<ClaimPage />} />
      </Route>
      <Route path="/transfer-agreement/:id" element={<DashboardLayout />}>
        <Route index element={<TransferAgreementPage />} />
      </Route> */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
